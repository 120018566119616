@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300&display=swap');
html{
  background-color: #282c34;
}

body{
  -webkit-animation: fadein 1.5s; 
  background: linear-gradient( rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75) ), #282c34;
  color: white;
}

